* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.quote-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto auto;
  padding: 40px;
  box-shadow: 1px 1px 5px black;
  position: relative;
}

#new-quote {
  position: absolute;
  left: 0;
  top: 0;
  width: fit-content;
  border-bottom-right-radius: 10px;
}

#quote-content {
  text-align: center;
}

#quote-author {
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .quote-container {
    width: 50%;
  }
}
