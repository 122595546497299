@import url('https://fonts.googleapis.com/css2?family=Solway:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Solway', serif;
}

body {
  width: 100vw;
  height: 100vh;
}

.calculator-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  height: 80vh;
}

#slogan {
  font-size: 32px;
}

#total,
#next,
#op {
  font-size: 1.2rem;
  height: 40%;
  width: 100%;
  color: white;
  background-color: grey;
  display: flex;
  align-items: center;
  padding: 0 5px;
  justify-content: right;
}

#op {
  height: 20%;
  font-size: 0.8rem;
  color: orange;
}

#next {
  color: lightgray;
  font-size: 0.9rem;
}

.calculator {
  width: 300px;
  margin: auto auto;
}

.result-screen {
  height: 70px;
}

.button-section {
  background-color: white;
  display: grid;
  gap: 1px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
